import React, { PropsWithChildren, ReactNode } from 'react';
import Skeleton, { SkeletonTheme, SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Image from 'next/legacy/image';
import clsx from 'clsx';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import IconButton from '../buttons/IconButton/IconButton';

// FIXME: figure out a better way to do this. pure ui components should not depend on feature components (we should have made ui-components a package to enfore this)
import appHeaderClasses from '../../features/app/shells/MembersApp/DefaultLayout/Header/Header.module.scss';
import classes from './AppSkeleton.module.scss';

const SideMenuBaseColor = '#202020';

function SideMenuItemWrapper({ children }: PropsWithChildren<unknown>) {
  return (
    <div className={classes.sideMenuWrapper}>
      {children}
      <Skeleton height={8} width={60} enableAnimation={false} baseColor={SideMenuBaseColor} />
    </div>
  );
}

const SideMenuItem: React.FC<SkeletonProps> = (props) => (
  <Skeleton
    height={45}
    width={45}
    baseColor={SideMenuBaseColor}
    enableAnimation={false}
    wrapper={SideMenuItemWrapper}
    circle
    inline
    {...props}
  />
);

export const SkeletonRowStack = ({ className }: { className?: string }) => (
  <div className={clsx(classes.rowStack, className)}>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <Skeleton />
  </div>
);

export default function AppSkeleton({ children }: { children?: ReactNode }) {
  return (
    <SkeletonTheme height={52} highlightColor="white" duration={2} borderRadius={0}>
      <div className={classes.root}>
        <div className={classes.sideMenu}>
          <div className={classes.logo}>
            <Image src="/images/logo.png" width="40" height="40" alt="Market Movers" />
          </div>
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
          <SideMenuItem />
        </div>
        <div className={classes.content}>
          <div className={clsx(appHeaderClasses.wrapper)}>
            <div className={appHeaderClasses.mobileMenu}>
              <IconButton faIcon={faBars} title="Open menu" size="large" color="default" />
            </div>
            <Skeleton height="100%" className={appHeaderClasses.collectibleTypeSelector} />
            <Skeleton height="100%" className={clsx(classes.search, appHeaderClasses.search)} />
            <div className={clsx(classes.rightSection, appHeaderClasses.rightSection)}>
              <Skeleton height={35} width={100} direction="rtl" borderRadius={30} className={classes.helpMenu} />
              <Skeleton circle className={classes.userAccount} height={40} width={40} />
            </div>
          </div>
          <div className={classes.page}>
            {!!children ? (
              children
            ) : (
              <>
                <Skeleton width="100%" />
                <Skeleton width="40%" style={{ marginTop: 40, marginLeft: 30 }} direction="rtl" />
                <SkeletonRowStack className={classes.smallRowStack} />
                <SkeletonRowStack className={classes.smallRowStack} />
                <SkeletonRowStack className={classes.smallRowStack} />
                <SkeletonRowStack />
                <SkeletonRowStack />
                <SkeletonRowStack />
                <SkeletonRowStack />
                <SkeletonRowStack />
                <SkeletonRowStack className={classes.smallRowStack} />
              </>
            )}
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
