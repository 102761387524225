import React from 'react';
import clsx from 'clsx';
import classes from './PopulationCount.module.scss';

export interface PopulationCountProps {
  /**
   * Population count of the card
   */
  count: number | undefined | null;
  className?: string;
  size?: Size;
  onClick?: () => void;
}

export type Size = 'medium' | 'large';

export default function PopulationCount({ count = null, className, size = 'medium', onClick }: PopulationCountProps) {
  if (!count) {
    return null; // NOTE: population 0 means collectible does not exist
  }
  const formattedCount = Number(count).toLocaleString('en-US');
  const text = `(Pop: ${formattedCount})`;
  const title = `Population count: ${formattedCount} cards`;
  const commonClasses = { [classes.medium]: size === 'medium', [classes.large]: size === 'large' };

  return onClick ? (
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      title={title}
      className={clsx(classes.root, classes.button, commonClasses, className)}
    >
      {text}
    </div>
  ) : (
    <span className={clsx(classes.root, classes.text, commonClasses, className)} title={title}>
      {text}
    </span>
  );
}
