import { useMemo, useState } from 'react';
import { faEllipsis, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { Dropdown, Menu, MenuProps, DropDownProps } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import IconButton, { IconButtonSize, IconButtonColor } from '../buttons/IconButton/IconButton';
import { useSelectPopupContainer } from '../forms/SimpleSelect/useSelectPopupContainer';

import classes from './ExtraActionsDropdown.module.scss';

export type ExtraActionsDropdownProps<TValue extends string = string> = Omit<DropDownProps, 'overlay' | 'children'> & {
  items: ExtraActionsItem<TValue>[];
  size?: IconButtonSize;
  color?: IconButtonColor;
  faIcon?: IconDefinition;
};

export type ExtraActionsItem<TValue extends string = string> = ExtraAction<TValue> | Group<TValue>;

export interface ExtraAction<TValue extends string = string> {
  id: TValue;
  label: string;
  Icon?: JSX.Element;
  faIcon?: IconDefinition;
  onSelect?: (value: TValue) => void;
  externalLink?: string;
  internalLink?: string;
  disabled?: boolean;
  highlighted?: boolean;
  danger?: boolean;
}

export interface Group<TValue extends string = string> {
  id: string;
  groupLabel: string;
  items: ExtraAction<TValue>[];
  disabled?: boolean;
  highlighted?: boolean;
  danger?: boolean;
}

type MenuItems = Exclude<MenuProps['items'], undefined>;

export default function ExtraActionsDropdown<TValue extends string = string>({
  className,
  disabled,
  placement = 'bottomRight',
  trigger = ['click'],
  items,
  size = 'small',
  color = 'default',
  faIcon = faEllipsis,
  ...otherProps
}: ExtraActionsDropdownProps<TValue>) {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { getPopupContainer } = useSelectPopupContainer();
  const menuItems = useMemo<MenuItems>(() => {
    const hasIcons = items.some((item) => !isGroup(item) && (item.Icon || item.faIcon));
    const iconSpacer = hasIcons ? <span className={classes.noIconSpacer} /> : null;
    return items.reduce<MenuItems>((acc, item) => {
      if (isGroup(item)) {
        const group = item;
        const hasIcons = group.items.some((subItem) => subItem.Icon || subItem.faIcon);
        const iconSpacer = hasIcons ? <span className={classes.noIconSpacer} /> : null;
        acc?.push({
          key: group.id,
          type: 'group',
          label: group.groupLabel,
          className: clsx(classes.menuItem, {
            [classes.highlighted]: group.highlighted,
            [classes.danger]: group.danger,
          }),
          children: group.items.map((subItem) => ({
            key: subItem.id,
            label: subItem.label,
            icon: subItem.Icon ?? (subItem.faIcon ? <FontAwesomeIcon icon={subItem.faIcon} /> : iconSpacer),
            className: clsx(classes.menuItem, {
              [classes.highlighted]: subItem.highlighted,
              [classes.danger]: subItem.danger,
            }),
            disabled: subItem.disabled || group.disabled,
            onClick: (e) => {
              e.domEvent.stopPropagation();
              setIsOpen(false);
              subItem.onSelect?.(subItem.id);
              if (subItem.externalLink) {
                window.open(subItem.externalLink, '__blank');
              }
              if (subItem.internalLink) {
                router.push(subItem.internalLink);
              }
            },
          })),
        });
      } else {
        acc?.push({
          key: item.id,
          label: item.label,
          icon: item.Icon ?? (item.faIcon ? <FontAwesomeIcon icon={item.faIcon} /> : iconSpacer),
          className: clsx(classes.menuItem, { [classes.highlighted]: item.highlighted, [classes.danger]: item.danger }),
          disabled: item.disabled,
          onClick: (e) => {
            e.domEvent.stopPropagation();
            setIsOpen(false);
            item.onSelect?.(item.id);
            if (item.externalLink) {
              window.open(item.externalLink, '__blank');
            }
            if (item.internalLink) {
              router.push(item.internalLink);
            }
          },
        });
      }
      return acc;
    }, []);
  }, [items, router]);

  return (
    <Dropdown
      {...otherProps}
      overlay={<Menu items={menuItems} />}
      disabled={disabled}
      placement={placement}
      trigger={trigger}
      className={className}
      getPopupContainer={getPopupContainer}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <IconButton
        disabled={disabled}
        title="More actions"
        faIcon={faIcon}
        size={size}
        color={color}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </Dropdown>
  );
}

function isGroup<TValue extends string = string>(item: ExtraActionsItem<TValue>): item is Group<TValue> {
  return !!(item as Group<TValue>).items;
}
