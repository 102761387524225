import { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import classes from './GradeIndicator.module.scss';
import { useSelectPopupContainer } from '@/sci-ui-components/forms/SimpleSelect/useSelectPopupContainer';

export interface GradeIndicatorProps {
  /**
   * Grade of the card
   */
  grade: string | undefined | null;
  alternateGrades?: AlternateGrade[] | null;
  onGradeChange?: (alternateGrade: AlternateGrade) => void;
  className?: string;
  gradeTextClassName?: string;
  size?: Size;
  gradeIconClassName?: string;
}

export type Size = 'medium' | 'large';

export interface AlternateGrade {
  collectibleId: number;
  gradeName: string;
}

/**
 * A visual indicator of the grade of a sport card
 */
export default function GradeIndicator({
  grade = null,
  alternateGrades,
  onGradeChange,
  className,
  gradeTextClassName,
  size = 'medium',
  gradeIconClassName,
}: GradeIndicatorProps) {
  const { getPopupContainer } = useSelectPopupContainer();

  const [isOpen, setIsOpen] = useState(false);
  if (!grade) {
    return null;
  }

  if (alternateGrades?.length && onGradeChange) {
    const handleGradeChange = (selectedCollectibleId: number) => {
      const alternateGrade = alternateGrades.find(({ collectibleId }) => selectedCollectibleId === collectibleId);
      if (alternateGrade) {
        onGradeChange(alternateGrade);
      }
    };
    return (
      <span className={clsx(classes.root, { [classes.large]: size === 'large' }, className)}>
        <Dropdown
          open={isOpen}
          onOpenChange={setIsOpen}
          getPopupContainer={getPopupContainer}
          overlay={
            <Menu
              items={alternateGrades.map(({ collectibleId, gradeName }) => ({
                label: gradeName,
                key: String(collectibleId),
                onClick: (e) => {
                  e.domEvent.stopPropagation();
                  setIsOpen(false);
                  handleGradeChange(collectibleId);
                },
              }))}
              className={classes.menu}
            />
          }
          trigger={['click']}
        >
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            title={grade}
            className={classes.button}
          >
            <span className={clsx(classes.gradeText, gradeTextClassName)}>{grade}</span>
            <FontAwesomeIcon icon={faCaretDown} className={clsx(classes.icon, gradeIconClassName)} />
          </button>
        </Dropdown>
      </span>
    );
  }

  return (
    <span className={clsx(classes.root, { [classes.large]: size === 'large' }, className)}>
      <span className={clsx(classes.gradeText, gradeTextClassName)} title={grade}>
        {grade}
      </span>
    </span>
  );
}
