import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

import classes from './CollectibleText.module.scss';

type TextType = 'title' | 'description';
type CollectibleTextProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  type?: TextType;
  size?: Size;
};

type Size = 'large' | 'medium';

export default function CollectibleText({
  children,
  className,
  type = 'description',
  size,
  ...other
}: CollectibleTextProps) {
  if (!children) {
    return null;
  }
  return React.createElement(
    type === 'title' ? 'h4' : 'p',
    {
      ...other,
      className: clsx(
        {
          [classes.collectibleDescriptionText]: type === 'description',
          [classes.collectibleTitleText]: type === 'title',
          [classes.large]: size === 'large',
        },
        className
      ),
    },
    children
  );
}
